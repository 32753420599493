var Handlebars = require("../js/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.profile : depth0)) != null ? stack1.full_name : stack1), depth0))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.profile : depth0)) != null ? stack1.github_username : stack1), depth0))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                                <i class=\"fas fa-heart\"></i>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                                <i class=\"far fa-heart\"></i>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <a href=\" /tag/"
    + alias4(((helper = (helper = helpers.slug || (depth0 != null ? depth0.slug : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"slug","hash":{},"data":data}) : helper)))
    + "/top/\">\n                        <span class=\"text-gray-600 text-sm mx-1 bg-gray-100 rounded px-2 py-1\">"
    + alias4(((helper = (helper = helpers.slug || (depth0 != null ? depth0.slug : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"slug","hash":{},"data":data}) : helper)))
    + "</span>\n                    </a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                    <a>\n                        <span class=\"boost_button\" data-post-id=\""
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                            <i class=\"fas fa-magic\"></i>\n                        </span>\n                    </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, alias5="function";

  return "<div class=\"mb-5\">\n    <div class=\"md:flex\">\n        <div classs=\"max-w-lg\">\n            <div class=\"flex justify-start items-center\">\n                <img class=\"flex-initial h-5 rounded\"\n                    src=\"https://avatars.githubusercontent.com/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.profile : depth0)) != null ? stack1.github_username : stack1), depth0))
    + "?s=64\">\n                <a class=\"flex-initial font-medium has-text-dark mx-2 text-sm\" href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.profile : depth0)) != null ? stack1.github_username : stack1), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.profile : depth0)) != null ? stack1.full_name : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "                </a>\n            </div>\n            <h2>\n                <a href=\""
    + alias2(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"link","hash":{},"data":data}) : helper)))
    + "\" class=\"post_link text-2xl font-semibold text-gray-900\" target=\"_blank\"\n                    rel=\"noopener noreferrer\">\n                    "
    + alias2(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"title","hash":{},"data":data}) : helper)))
    + "\n                </a>\n            </h2>\n\n            <h3 class=\"text-gray-600\">\n                "
    + alias2(((helper = (helper = helpers.summary || (depth0 != null ? depth0.summary : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"summary","hash":{},"data":data}) : helper)))
    + "\n            </h3>\n\n            <div class=\"flex mt-2 flex-wrap -ml-1\">\n                <div class=\"mr-2\">\n                    <a data-toggle=\"tooltip\" title=\"Like post\">\n                        <span class=\"icon text-gray-600 text-sm\">\n                            <span data-post-id=\""
    + alias2(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-vote-upvoted=\""
    + alias2(((helper = (helper = helpers.upvoted || (depth0 != null ? depth0.upvoted : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"upvoted","hash":{},"data":data}) : helper)))
    + "\" class=\"vote_button\">\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.upvoted : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "                            </span>\n                        </span>\n                        <span class=\"text-gray-600 text-sm -ml-1\"\n                            id=\"feed_upvotes_counter_"
    + alias2(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias2(((helper = (helper = helpers.aggregate_votes_count || (depth0 != null ? depth0.aggregate_votes_count : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"aggregate_votes_count","hash":{},"data":data}) : helper)))
    + "</span>\n                    </a>\n                </div>\n\n                <div class=\"mr-2\">\n                    <a class=\"comment_link text-gray-600 text-sm\" data-toggle=\"tooltip\" title=\"Discussions\"\n                        href=\"/post/"
    + alias2(((helper = (helper = helpers.slug || (depth0 != null ? depth0.slug : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"slug","hash":{},"data":data}) : helper)))
    + "\">\n                        <i class=\"far fa-comment\"></i>\n                    </a>\n                </div>\n\n                <div class=\"mr-2\">\n                    <a class=\"text-gray-600 text-sm\">\n                        "
    + alias2(((helper = (helper = helpers.time || (depth0 != null ? depth0.time : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"time","hash":{},"data":data}) : helper)))
    + "\n                    </a>\n                </div>\n\n                <div class=\"\">\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.topics : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n\n\n                <div>\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.is_admin : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n\n            </div>\n\n        </div>\n\n        <!--div>\n            <img class=\"rounded-lg\" src=\""
    + alias2(((helper = (helper = helpers.cover_photo_url || (depth0 != null ? depth0.cover_photo_url : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"cover_photo_url","hash":{},"data":data}) : helper)))
    + "\">\n        </div-->\n    </div>\n\n</div>";
},"useData":true});